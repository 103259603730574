<template>
  <div class="d-flex align-center">
    <v-icon v-if="simple" :color="checked ? 'primary' : ''" @click="$emit('change', !checked)">
      {{ checked ? 'check_box' : 'check_box_outline_blank' }}
    </v-icon>
    <v-btn v-else :loading="loading" :disabled="loading" icon @click="$emit('change', !checked)">
      <v-icon :color="checked ? 'primary' : ''">
        {{ checked ? 'check_box' : 'check_box_outline_blank' }}
      </v-icon>
    </v-btn>
    <span v-if="label" class="v-label ml-1 clickable" @click="$emit('change', !checked)">{{
      label
    }}</span>
  </div>
</template>

<script>
// for when explicit control is needed for checking the checkbox;
// e.g. it's false, after a click a request is sent, and only on that request completion
// it should be set to checked. It's complicated to get it done with v-checkbox
export default {
  name: 'BaseSimpleCheckbox',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>

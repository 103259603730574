<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ editedUser.personal_data ? editedUser.personal_data.full_name : '' }}
        {{ $t('employees.general.role_and_permissions') }}
      </v-card-title>
      <v-card-text class="px-0">
        <v-select
          :value="editedUser.role"
          :label="$t('employees.general.user_role')"
          :items="userRoles"
          :loading="loading[`put:api/users/${editedUser.id}/change-role`]"
          class="px-6"
          @change="
            updateUserRole({ user: editedUser, newRole: $event });
            clearPermissions();
          "
        />
        <v-expansion-panels v-show="editedUser.role !== 'admin'" flat accordion>
          <v-expansion-panel
            v-for="(group, groupName) of allowedPermissions"
            :key="groupName"
            class="border-b"
          >
            <v-expansion-panel-header>
              {{ $t(`permissions['${groupName}']`) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col v-for="permission in group" :key="permission" cols="12" sm="6">
                  <BaseSimpleCheckbox
                    :checked="userPermissions[permission]"
                    :label="$t(`permissions['${permission}']`)"
                    :loading="pendingPermissions[permission]"
                    @change="onPermissionToggle(permission)"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="goBack">
          {{ $t('general.actions.done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import BaseSimpleCheckbox from '@/components/base/BaseSimpleCheckbox';
import clone from 'just-clone';

export default {
  name: 'UserPermissionsForm',

  components: { BaseSimpleCheckbox },

  data() {
    return {
      pendingPermissions: {},
    };
  },

  computed: {
    ...mapState('auth', ['allPermissions']),
    ...mapState('users', ['editedUser', 'userFilterParams']),
    ...mapGetters(['loading']),
    ...mapGetters('users', ['userRoles']),

    allowedPermissions() {
      const permissions = clone(this.allPermissions);
      const clientPermissions = ['projects', 'documents', 'invoices', 'users', 'received_invoices'];
      if (this.editedUser.role === 'client') {
        Object.keys(permissions).forEach((permissionKey) => {
          if (!clientPermissions.includes(permissionKey)) {
            delete permissions[permissionKey];
          }
          // Projects: for clients creating project is restricted
          permissions.projects = permissions.projects.filter(
            (permission) => permission === 'projects.*',
          );
        });
      }

      if (this.editedUser.role === 'employee') {
        delete permissions.users;
      }
      return permissions;
    },

    userPermissions() {
      const userPermissions = {};
      for (let i = 0; i < this.editedUser.permissions?.length; i++) {
        const { permission } = this.editedUser.permissions[i];
        userPermissions[permission] = true;
      }
      return userPermissions;
    },
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('users/editUser', to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'users', query: store.state.users.userFilterParams });
      });
  },

  created() {
    Object.keys(this.allPermissions).forEach((group) => {
      this.allPermissions[group].forEach((permission) => {
        this.userPermissions[permission] = false;
      });
    });

    for (let i = 0; i < this.editedUser.permissions?.length; i++) {
      const { permission } = this.editedUser.permissions[i];
      this.userPermissions[permission] = true;
    }
  },

  methods: {
    ...mapActions('users', ['updateUserRole', 'toggleUserPermission']),

    async onPermissionToggle(permission) {
      this.$set(this.pendingPermissions, permission, true);
      await this.toggleUserPermission({ user: this.editedUser, permission });
      this.$delete(this.pendingPermissions, permission);
    },

    clearPermissions() {
      this.$set(this.editedUser, 'permissions', []);
    },

    goBack() {
      this.$router.push({ name: 'users', query: this.userFilterParams });
    },
  },
};
</script>
